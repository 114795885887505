import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import firebase, { firebase_app, useAuth } from "./firebase";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  Jumbotron,
  ListGroup,
  Nav,
  Navbar,
  NavbarBrand,
  Row,
} from "react-bootstrap";
import { IndexLinkContainer, LinkContainer } from "react-router-bootstrap";
import StyledFirebaseAuth from "react-firebaseui/FirebaseAuth";
import { useDispatch, useSelector } from "react-redux";
import { ConnectionState, disconnect, getRecent } from "./client";
import Sim from "./Sim";
import {
  set_full_screen,
  set_inspect,
  set_render_type,
  set_table_type,
} from "./visual";
import { TABLE_MATERIALS } from "./coaster/GLCoaster";
import { MdCode, MdFullscreen, MdFullscreenExit } from "react-icons/md";

export default function App() {
  const { is_signed_in, is_allowed } = useAuth();
  if (is_signed_in === null) {
    // To avoid showing the login prompt for a moment on load
    // when we're already signed in, this just shows a black screen
    // until firebase confirms explicitly whether we're already signed in.
    //  i.e. while is_signed_in is `null` and not yet `true` or `false`
    return <div />;
  }
  if (is_signed_in === false) {
    // explicitly false means firebase confirmed we're not signed in
    return <LoginPrompt />;
  }
  if (is_allowed === null) {
    // We're signed in (authenticated)
    // but we're waiting to check if we're allowed (authorization).
    return <div />;
  }
  if (is_allowed === false) {
    return <NotAllowed />;
  }
  // we're signed in and allowed, so take them to the page
  return (
    <Router>
      <SimulatorNav />
      <Switch>
        <Route path="/sim/:hardware_identifier" component={Sim} />
        <Route path="/" component={Home} />
      </Switch>
    </Router>
  );
}

function Home() {
  let recent = getRecent();
  let new_hardware_identifier =
    "sim_" + Math.random().toString(16).substr(2, 8);
  return (
    <Container className="pt-4">
      <Row>
        <Col></Col>
        <Col>
          <ListGroup>
            <IndexLinkContainer
              key={new_hardware_identifier}
              to={`/sim/${new_hardware_identifier}`}
            >
              <ListGroup.Item action>New simulated device</ListGroup.Item>
            </IndexLinkContainer>
            <hr />
            {!recent.length ? null : (
              <h5 className="text-muted">Recently simulated</h5>
            )}
            {recent.map((hid) => (
              <IndexLinkContainer key={hid} to={`/sim/${hid}`}>
                <ListGroup.Item action className="text-muted">
                  {hid}
                </ListGroup.Item>
              </IndexLinkContainer>
            ))}
          </ListGroup>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
}

function LoginPrompt() {
  return (
    <Jumbotron>
      <h1>Simulator</h1>
      <p>TK: something about how it's all a simulation anyways.</p>
      <div>
        <StyledFirebaseAuth
          className="justify-content-start"
          uiConfig={{
            signInFlow: "popup", // not redirect
            signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
            callbacks: {
              signInSuccessWithAuthResult: () => false, // prevent redirect after login
            },
          }}
          firebaseAuth={firebase_app.auth()}
        />
      </div>
    </Jumbotron>
  );
}

function NotAllowed() {
  return (
    <Jumbotron>
      <h1>Simulator</h1>
      <p>Sorry, it looks like you don't have access yet.</p>
      <p>
        {firebase_app.auth().currentUser.displayName}{" "}
        <Button variant="link" onClick={() => firebase.auth().signOut()}>
          sign out
        </Button>
      </p>
    </Jumbotron>
  );
}

function SimulatorNav() {
  let dispatch = useDispatch();
  let full_screen = useSelector((state) => state.visual.full_screen);
  let inspect = useSelector((state) => state.visual.inspect);
  let render_type = useSelector((state) => state.visual.render_type);
  let table_type = useSelector((state) => state.visual.table_type);
  let hardware_identifier = useSelector(
    (state) => state.client.hardware_identifier
  );
  let broker_url = useSelector((state) => state.client.broker_url);
  let connection_state = useSelector((state) => state.client.connection_state);
  return (
    <Navbar bg="dark" variant="dark">
      <LinkContainer to="/">
        <NavbarBrand>Simulator</NavbarBrand>
      </LinkContainer>
      {connection_state !== ConnectionState.connected ? null : (
        <Nav>
          <div className="d-flex flex-column mr-2">
            <h6>{hardware_identifier}</h6>
            <small>
              <code>{broker_url}</code>
            </small>
          </div>
          <Button
            variant="secondary"
            className="mt-1 mr-2"
            size="sm"
            onClick={() => dispatch(disconnect())}
          >
            Disconnect
          </Button>
          <Button
            className="mt-1 mr-2"
            variant="secondary"
            size="sm"
            onClick={() => dispatch(set_full_screen(!full_screen))}
          >
            {full_screen ? (
              <MdFullscreenExit size={24} />
            ) : (
              <MdFullscreen size={24} />
            )}
          </Button>
          <ButtonGroup className="mt-1 mr-2" toggle>
            {["svg", "gl"].map((t) => (
              <Button
                active={render_type === t}
                size="sm"
                variant="secondary"
                key={t}
                onClick={() => dispatch(set_render_type(t))}
              >
                {t}
              </Button>
            ))}
          </ButtonGroup>
          {render_type !== "gl" ? null : (
            <>
              <Dropdown
                className="mt-1 mr-2"
                variant="secondary"
                onSelect={(t) => dispatch(set_table_type(t))}
              >
                <Dropdown.Toggle variant="secondary">
                  {table_type}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {Object.keys(TABLE_MATERIALS).map((t) => (
                    <Dropdown.Item
                      key={t}
                      eventKey={t}
                      active={table_type === t}
                    >
                      {t}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Button
                className="mt-1 mb-1 mr-auto"
                size="sm"
                variant="secondary"
                onClick={() => dispatch(set_inspect(!inspect))}
              >
                <MdCode />
              </Button>
            </>
          )}
        </Nav>
      )}
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text>
          {firebase_app.auth().currentUser.displayName}{" "}
          <Button variant="link" onClick={() => firebase_app.auth().signOut()}>
            sign out
          </Button>
        </Navbar.Text>
      </Navbar.Collapse>
    </Navbar>
  );
}
