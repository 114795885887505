import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MQTT from "async-mqtt";
import _ from "lodash";
import { proto, topics } from "./proto";

const EventPayload = proto.lookupType("EventPayload");
const DirectiveRequestPayload = proto.lookupType("DirectiveRequestPayload");
const DirectiveResponsePayload = proto.lookupType("DirectiveResponsePayload");

const UpdateGroupStateRequest = proto.lookupType("UpdateGroupStateRequest");

const TO_OBJECT_CONFIG = {
  defaults: true,
  bytes: String,
  arrays: true,
  longs: true,
  objects: true,
};

/**
 * @type {AsyncMqttClient}
 * @private
 */
let _mqtt = null;

export const ConnectionState = {
  disconnected: "disconnected",
  pending: "pending",
  failed: "failed",
  connected: "connected",
};

export const connect = createAsyncThunk(
  "client/connect",
  async (
    { broker_url, hardware_identifier },
    { dispatch, signal, getState }
  ) => {
    // first cleanup any prior client
    _mqtt && _mqtt.end(/* force */ true);

    _mqtt = await MQTT.connectAsync(broker_url, {
      clientId: hardware_identifier,
    });
    console.log("mqtt:connected");
    _mqtt.on("message", (topic, message) => {
      console.log("mqtt:message", topic);
      let req = DirectiveRequestPayload.decode(message);
      let obj = DirectiveRequestPayload.toObject(req, TO_OBJECT_CONFIG);
      dispatch(directive_request_received(obj));

      // TODO: consider rolling auto-responses and handlers into a thunk for the initial receipt.
      let { request_id, directive } = obj;

      if (directive_handlers[directive]) {
        directive_handlers[directive]({ req, getState, dispatch });
      }

      let { auto_responses } = getState().client;
      if (auto_responses[directive]) {
        let { status_code, response } = auto_responses[directive];
        dispatch(
          respond({
            request_id,
            directive,
            status_code,
            response,
            execution_ms: 0,
            device_responded_at: Date.now(),
          })
        );
      }
    });
    await _mqtt.subscribe(`req/${hardware_identifier}/#`);
    console.log("mqtt:subscribed");
    addRecent(hardware_identifier);
    return true; // resolve w/ bool so it can serialize into an action
  }
);

export function getRecent() {
  let recent_csv = localStorage.getItem("recent_hardware_identifiers") || "";
  return _.without(recent_csv.split(","), "");
}

const MAX_RECENT_COUNT = 10;
function addRecent(hardware_identifier) {
  let recent = getRecent();
  let updated = _.uniq([hardware_identifier].concat(recent))
    .slice(0, MAX_RECENT_COUNT)
    .join(",");
  localStorage.setItem("recent_hardware_identifiers", updated);
}

export const disconnect = createAsyncThunk("client/disconnect", () => {
  _mqtt && _mqtt.end(/* force */ true);
  _mqtt = null;
});

export const emit = createAsyncThunk(
  "client/emit",
  async function (
    { event_id, event, device_emitted_at, info },
    { signal, getState }
  ) {
    if (!_mqtt) {
      return false;
    }
    let { hardware_identifier } = getState().client;
    let created = EventPayload.create({
      event_id,
      device_emitted_at,
      event,
      info,
    });
    let encoded = EventPayload.encode(created).finish();
    await _mqtt.publish(`e/${hardware_identifier}/${event}`, encoded);
    return true;
  }
);

export const respond = createAsyncThunk(
  "client/respond",
  async function (arg, { signal, getState }) {
    if (!_mqtt) {
      return false;
    }
    let { hardware_identifier } = getState().client;
    let created = DirectiveResponsePayload.fromObject(arg);
    let encoded = DirectiveResponsePayload.encode(created).finish();
    let topic = `res/${hardware_identifier}/${created.directive}`;
    await _mqtt.publish(topic, encoded);
    return true;
  }
);

const directive_handlers = {
  [topics.Directive.UPDATE_GROUP_STATE]: ({ req, getState, dispatch }) => {
    let request = UpdateGroupStateRequest.decode(req.request);
    let {
      my_member_id /* TODO: group_state */,
    } = UpdateGroupStateRequest.toObject(request, TO_OBJECT_CONFIG);
    dispatch(
      updated_group({
        my_member_id,
        // TODO: fix group_state construction in the admin tool
        // group_state,
        group_state: {
          captured_at: Date.now(),
          group_members: [
            {
              member_id: "12345",
              last_active_at: Date.now(),
              color: {
                red: 0,
                green: Math.pow(2, 32), // full green
                blue: 0,
              },
            },
          ],
        },
      })
    );
  },
};

export const clientSlice = createSlice({
  name: "client",
  initialState: {
    hardware_identifier: "",
    broker_url: "",
    connection_state: ConnectionState.disconnected,
    events: [],
    directive_requests: [],
    directive_responses: [],
    auto_responses: {},
    group: null,
  },
  reducers: {
    saved_auto_response: (state, action) => {
      let { directive, status_code, response } = action.payload;
      state.auto_responses[directive] = {
        status_code,
        response,
      };
    },
    deleted_auto_response: (state, action) => {
      let { directive } = action.payload;
      delete state.auto_responses[directive];
    },
    directive_request_received: (state, action) => {
      state.directive_requests.push({
        id: action.payload.request_id,
        at: Date.now(),
        req: action.payload,
      });
    },
    updated_group: (state, action) => {
      state.group = action.payload;
    },
  },
  extraReducers: {
    [connect.pending]: (state, action) => {
      let { broker_url, hardware_identifier } = action.meta.arg;
      state.broker_url = broker_url;
      state.hardware_identifier = hardware_identifier;
      state.connection_state = ConnectionState.pending;
      state.events = [];
    },
    [connect.fulfilled]: (state, action) => {
      state.connection_state = ConnectionState.connected;
    },
    [connect.rejected]: (state, action) => {
      state.connection_state = ConnectionState.failed;
    },
    [disconnect.pending]: (state, action) => {
      state.connection_state = ConnectionState.disconnected;
    },
    [emit.pending]: (state, action) => {
      let created = EventPayload.create(action.meta.arg);
      let obj = EventPayload.toObject(created, TO_OBJECT_CONFIG);
      state.events.push({
        id: obj.event_id,
        at: obj.device_emitted_at,
        event: obj,
      });
    },
    [emit.rejected]: (state, action) => {},
    // TODO: emit.rejected -> mark event as failed?
    [respond.pending]: (state, action) => {
      let obj = DirectiveResponsePayload.toObject(
        action.meta.arg,
        TO_OBJECT_CONFIG
      );
      state.directive_responses.push({
        id: `res-${obj.request_id}`,
        at: obj.device_responded_at,
        res: obj,
      });
    },
  },
});

const { directive_request_received, updated_group } = clientSlice.actions;

export const {
  saved_auto_response,
  deleted_auto_response,
} = clientSlice.actions;
export default clientSlice.reducer;
