import { Canvas, useLoader, useThree } from "@react-three/fiber";
import { RepeatWrapping, TextureLoader } from "three";
import { STLLoader } from "./STLLoader.js";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

export default function GLCoaster({ className, full, inspect, colors }) {
  let table_type = useSelector((state) => state.visual.table_type);
  return (
    <div style={full ? { height: "100vh" } : { height: "200px" }}>
      <Canvas className={className}>
        <PerspectiveCamera
          makeDefault
          position={[0, -300, 150]}
          up={[0, 0, 1]}
        />
        <Suspense fallback={null}>
          <STLCoaster colors={colors} inspect={inspect} />
          <TableTop inspect={inspect} type={table_type} />
        </Suspense>

        <directionalLight />
        <pointLight position={[2000, 2000, 4000]} intensity={0.15} />
        <ambientLight intensity={0.2} />
        <OrbitControls
          enableZoom={true}
          enablePan={false}
          minDistance={10}
          maxDistance={500}
          minPolarAngle={0}
          maxPolarAngle={Math.PI / 2.001}
        />
      </Canvas>
    </div>
  );
}

export const TABLE_MATERIALS = {
  wood: { url: "/wood.jpg", shininess: 0, repeatX: 10, repeatY: 20 },
  varnished: { url: "/wood.jpg", shininess: 80, repeatX: 10, repeatY: 20 },
  fabric: { url: "/fabric.jpg", shininess: 10, repeatX: 10, repeatY: 20 },
  denim: { url: "/denim.jpg", shininess: 10, repeatX: 10, repeatY: 20 },
  plaster: { url: "/plaster.jpg", shininess: 30, repeatX: 10, repeatY: 20 },
  brick: { url: "/brick.jpg", shininess: 0, repeatX: 2, repeatY: 3 },
};

function TableTop({ inspect, type = "wood" }) {
  let { url, shininess, repeatX, repeatY } = TABLE_MATERIALS[type];
  const texture = useLoader(TextureLoader, url);
  // TODO: use ao, roughness, specular maps too
  texture.wrapS = RepeatWrapping;
  texture.wrapT = RepeatWrapping;
  texture.offset.set(0, 0);
  texture.repeat.set(repeatX, repeatY);
  if (inspect) {
    return <></>;
  }
  return (
    <mesh>
      <planeGeometry args={[3000, 3000]} />
      <meshPhongMaterial
        map={texture}
        attach="material"
        shininess={shininess}
      />
    </mesh>
  );
}

// TODO: pull these from coaster.scad
const LEDS = [
  [5.251, 63.215, 72],
  [21.962, 86.215, 36],
  [49, 95, 0],
  [76.038, 86.215, 324],
  [92.749, 63.215, 288],
  [92.749, 34.785, 252],
  [76.038, 11.785, 216],
  [49, 3, 180],
  [21.962, 11.785, 144],
  [5.251, 34.785, 108],
].map(([x, y, z]) => [x - 48.8, y - 48.8, 7.07]);

function STLCoaster({ colors, inspect = false }) {
  // The <item>.stl comes from:
  //   $ openscad -o web-simulator/public/gen/<item>.stl cad-coaster/simulator-<item>.scad
  // See web-simulator-deploy.yaml
  const housing = useLoader(STLLoader, "/gen/housing.stl");
  const pcb = useLoader(STLLoader, "/gen/pcb.stl");
  const ref = useRef();
  const { camera } = useThree();
  const [frozen, set_frozen] = useState(false);
  useEffect(() => {
    camera.lookAt(ref.current.position);
  });
  return (
    <>
      <group castShadow ref={ref} onClick={() => set_frozen(!frozen)}>
        <mesh>
          <primitive object={housing} attach="geometry" />
          <meshPhongMaterial
            wireframe={inspect}
            opacity={0.5}
            color={0x808080}
          />
        </mesh>
        <mesh>
          <primitive object={pcb} attach="geometry" />
          <meshPhongMaterial
            wireframe={inspect}
            opacity={0.5}
            color={0x408040}
          />
        </mesh>
        {LEDS.map((position, i) => (
          <LED
            key={i}
            position={position}
            color={colors[(i + 2) % colors.length]}
          />
        ))}
      </group>
    </>
  );
}

function LED({ position, color = { r: 0, g: 0, b: 0 }, inspect = false }) {
  let [x, y, z] = position;
  let c = [color.r / 0xff, color.g / 0xff, color.b / 0xff];
  const spot = useRef();
  const { scene } = useThree();
  useEffect(() => {
    spot.current.target.position.set(2 * x, 2 * y, z);
    scene.add(spot.current.target);
  }, [scene, x, y, z]);
  let intensity = _.mean(c) * 40;
  return (
    <>
      <mesh position={position}>
        <meshStandardMaterial
          color={0x505050}
          emissive={c}
          emissiveIntensity={intensity}
        />
        <sphereGeometry args={[1]} color={c} />
      </mesh>
      <spotLight
        ref={spot}
        castShadow
        color={c}
        intensity={intensity}
        distance={300}
        decay={5}
        angle={Math.PI / 2}
        penumbra={0.8}
        position={position}
      />
    </>
  );
}
