import { configureStore, isPlain } from "@reduxjs/toolkit";
import client from "./client";
import visual from "./visual";

const isSerializable = (value) => isPlain(value) || value instanceof Uint8Array;

export default configureStore({
  reducer: {
    client,
    visual,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        isSerializable,
      },
    }),
});
