import protobufjs from "protobufjs";
import _ from "lodash";
import pb from "./gen/proto";

const proto_json = require("./gen/proto.json");
const _proto = protobufjs.Root.fromJSON(proto_json);
_proto.resolveAll();

export const topics = pb.com.glowpush.glendon.topics;

export const directive_names_by_value = _.invert(topics.Directive);
export const event_names_by_value = _.invert(topics.Event);
export const status_names_by_value = _.invert(topics.StatusCode);

export const proto = _proto;
