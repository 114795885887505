import { Redirect, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  ListGroup,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import {
  connect,
  ConnectionState,
  deleted_auto_response,
  emit,
  respond,
  saved_auto_response,
} from "./client";
import { v4 as uuid } from "uuid";
import { useState } from "react";
import {
  directive_names_by_value,
  event_names_by_value,
  proto,
  status_names_by_value,
  topics,
} from "./proto";
import _ from "lodash";
import moment from "moment";
import random_color from "randomcolor";
import { MdCode, MdDelete, MdDone, MdHelp, MdSend } from "react-icons/md";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { GroupCoaster } from "./coaster/Coaster";
const DeviceActivated = proto.lookupType("DeviceActivated");

export default function Sim() {
  let { hardware_identifier } = useParams();
  let dispatch = useDispatch();
  let connection_state = useSelector((state) => state.client.connection_state);
  let full_screen = useSelector((state) => state.visual.full_screen);
  let [transcript_item, set_transcript_item] = useState({});

  const emit_activated = () => {
    dispatch(
      emit({
        event_id: uuid(),
        device_emitted_at: Date.now(),
        event: topics.Event.DEVICE_ACTIVATED,
        info: DeviceActivated.encode({}).finish(),
      })
    );
  };
  if (!hardware_identifier.startsWith("sim_")) {
    return <Redirect to="/" />;
  }
  if (connection_state === ConnectionState.disconnected) {
    return (
      <Container className="mt-5">
        <ConnectForm {...{ hardware_identifier }} />
      </Container>
    );
  }
  if (full_screen) {
    return <GroupCoaster full onActivated={emit_activated} />;
  }
  return (
    <Container className="mt-5">
      <Row>
        <Col xs={3}>
          <GroupCoaster />
          <EventForm />
          <AutoResponses />
        </Col>
        <Col xs={5}>
          <DeviceTranscript
            selected={transcript_item}
            onSelected={set_transcript_item}
          />
        </Col>
        <Col xs={4}>
          {transcript_item.id ? (
            <div>
              TODO <pre>{JSON.stringify(transcript_item, null, 2)}</pre>
            </div>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
}

function AutoResponses() {
  let auto_responses = useSelector((state) => state.client.auto_responses);
  return (
    <Card className="mt-5">
      <Card.Header>
        Auto Responses{" "}
        <HelpIcon text="Automatic responses to incoming directive requests." />
      </Card.Header>
      <ListGroup variant="flush">
        {!Object.entries(auto_responses).length ? (
          <ListGroup.Item>
            <em className="text-muted">None</em>
          </ListGroup.Item>
        ) : null}
        {Object.entries(auto_responses).map(
          ([directive, { status_code, response }]) => (
            <AutoResponseListItem key={directive} directive={directive} />
          )
        )}
        {/* TODO: MdAdd for creating new auto response */}
      </ListGroup>
    </Card>
  );
}

function AutoResponseListItem({ directive }) {
  let dispatch = useDispatch();
  let { response, status_code } = useSelector(
    (state) => state.client.auto_responses[directive]
  );
  let [show_source, set_show_source] = useState(false);
  let decoded = null;
  if (show_source) {
    let type_name = _.upperFirst(
      _.camelCase(directive_names_by_value[directive])
    );
    let response_name = `${type_name}Response`;
    let response_type = proto.lookupType(response_name);
    decoded = response_type.decode(response);
  }
  return (
    <ListGroup.Item>
      <Container>
        <Row>
          <Col xs={4} className="p-0">
            <Button
              className="mr-1"
              size="sm"
              variant="secondary"
              onClick={() => dispatch(deleted_auto_response({ directive }))}
            >
              <MdDelete className="text-danger" />
            </Button>
            {/* TODO: MdEdit for editing auto responses */}
            <Button
              size="sm"
              variant="secondary"
              onClick={() => set_show_source(!show_source)}
            >
              <MdCode />
            </Button>
          </Col>
          <Col className="ml-0 pl-1 pt-1">
            <h6>
              {_.startCase(_.camelCase(directive_names_by_value[directive]))}
            </h6>
          </Col>
        </Row>
        {!show_source ? null : (
          <Row>
            <Col className="pt-3">
              <StatusBadge {...{ status_code }} />
              <pre className="mt-2">{JSON.stringify(decoded, null, 2)}</pre>
            </Col>
          </Row>
        )}
      </Container>
    </ListGroup.Item>
  );
}

function StatusBadge({ status_code }) {
  let name = status_names_by_value[status_code];
  return (
    <Badge pill variant={status_code ? "danger" : "success"}>
      {_.startCase(_.camelCase(name))}
    </Badge>
  );
}

function DeviceTranscript({ selected, onSelected }) {
  let events = useSelector((state) => state.client.events);
  let directive_requests = useSelector(
    (state) => state.client.directive_requests
  );
  let directive_responses = useSelector(
    (state) => state.client.directive_responses
  );

  // if (connection_state === ConnectionState.pending) {
  //   return <div>
  //     <p>TODO: present spinner</p>
  //   </div>
  // }
  // if (connection_state === ConnectionState.failed) {
  //   return <div>
  //     <p>TODO: present connection failure</p>
  //   </div>
  // }
  // connection_state === ConnectionState.connected
  let res_by_req_id = _.keyBy(directive_responses, ({ res }) => res.request_id);
  let transcript = _.orderBy(
    []
      .concat(events || [])
      .concat(
        (directive_requests || []).map((d) => ({
          ...d,
          has_response: d.req.request_id in res_by_req_id,
        }))
      )
      .concat(directive_responses || []),
    "at",
    "desc"
  );
  selected = selected || {};
  return (
    <Card>
      <ListGroup variant="flush">
        {transcript.length === 0 ? (
          <ListGroup.Item>
            <em className="text-muted">No transcript yet...</em>
          </ListGroup.Item>
        ) : null}
        {transcript.map((item, i) => (
          <ListGroup.Item
            key={item.id}
            as="div"
            action
            active={item.id === selected.id}
            onClick={() => onSelected(item)}
          >
            {item.event ? (
              <EventListItem key={i} emitted_at={item.at} event={item.event} />
            ) : item.req ? (
              <DirectiveRequestListItem
                key={i}
                received_at={item.at}
                req={item.req}
                has_response={item.has_response}
              />
            ) : item.res ? (
              <DirectiveResponseListItem
                key={i}
                responded_at={item.at}
                res={item.res}
              />
            ) : null}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Card>
  );
}

function EventListItem({ emitted_at, event }) {
  let color = "#555";
  let is_status = event.event === topics.Event.STATUS_UPDATED;
  return (
    <Container>
      <Row>
        <Col xs={3} className="p-0">
          <small className="text-muted">{moment(emitted_at).fromNow()}</small>
        </Col>
        <Col>
          <span className={is_status ? "text-muted" : ""}>
            {_.startCase(_.camelCase(event_names_by_value[event.event]))}
          </span>{" "}
          <FaArrowRight color={color} />
        </Col>
      </Row>
    </Container>
  );
}

function DirectiveResponseListItem({ responded_at, res }) {
  let { request_id, directive, status_code, execution_ms } = res;
  let color = random_color({ luminosity: "light", seed: request_id });
  let name = _.startCase(_.camelCase(directive_names_by_value[directive]));
  return (
    <Container>
      <Row>
        <Col xs={3} className="p-0">
          <small className="text-muted">{moment(responded_at).fromNow()}</small>
        </Col>
        <Col>
          {name}{" "}
          <small style={{ color }}>
            <StatusBadge {...{ status_code }} />{" "}
            {execution_ms < 2000
              ? `${execution_ms}ms`
              : `${(execution_ms / 1000.0).toFixed(1)}s`}
          </small>
          <FaArrowRight className="ml-1" color={color} />
        </Col>
      </Row>
    </Container>
  );
}

function DirectiveRequestListItem({ received_at, req, has_response }) {
  let { request_id, directive } = req;
  let color = random_color({ luminosity: "light", seed: request_id });
  return (
    <Container>
      <Row>
        <Col xs={3} className="p-0">
          <small className="text-muted">{moment(received_at).fromNow()}</small>
        </Col>
        <Col style={{ marginLeft: "-19px" }}>
          <div className="d-flex">
            <span className="mr-1">
              <FaArrowLeft color={color} />
            </span>
            <span>
              {_.startCase(_.camelCase(directive_names_by_value[directive]))}
            </span>
          </div>
        </Col>
        <Col xs={3} className="p-0 d-flex">
          {has_response ? (
            <MdDone className="ml-auto text-muted" />
          ) : (
            <RespondButton {...{ received_at, req }} />
          )}
        </Col>
      </Row>
    </Container>
  );
}

function RespondButton({ received_at, req }) {
  let dispatch = useDispatch();
  let [show, set_show] = useState(false);
  let [status_code, set_status_code] = useState(0);
  let [response, set_response] = useState({ is_ready: true, args: {} });
  let [auto, set_auto] = useState(false);

  let close = () => set_show(false);
  let { request_id, directive } = req;
  let color = random_color({ luminosity: "light", seed: request_id });
  let name = _.startCase(_.camelCase(directive_names_by_value[directive]));
  let type_name = _.upperFirst(
    _.camelCase(directive_names_by_value[directive])
  );
  let response_name = `${type_name}Response`;
  let response_type = proto.lookupType(response_name);
  let response_encoded = response_type.encode(response.args).finish();
  return (
    <>
      <Button
        className="ml-auto"
        color={color}
        size="sm"
        onClick={() => set_show(true)}
      >
        Respond
      </Button>
      <Modal show={show} onHide={close} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>{name} Response</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropdown onSelect={(value) => set_status_code(Number(value))}>
            <Dropdown.Toggle
              size="lg"
              variant={status_code ? "danger" : "success"}
            >
              {_.startCase(_.camelCase(status_names_by_value[status_code]))}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Header key="header">
                <h5>Status Code</h5>
                <p>Indicate whether the directive request was handled.</p>
              </Dropdown.Header>
              <Dropdown.Divider key="spacer" />
              {_.orderBy(
                Object.entries(status_names_by_value),
                ([code, name]) => code
              ).map(([code, name]) => (
                <Dropdown.Item
                  key={code}
                  eventKey={code}
                  active={status_code === Number(code)}
                >
                  {_.startCase(_.camelCase(name))}
                  {Number(code) === 0 ? (
                    <small className="ml-1 text-muted">
                      default, this indicates success
                    </small>
                  ) : null}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <ProtoTypeForm
            type={response_type}
            args={response.args}
            onChange={(response) => set_response(response)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="mr-auto" onClick={close}>
            Cancel
          </Button>
          <Form.Check
            inline
            type="checkbox"
            id="auto_respond"
            label="Auto"
            checked={!!auto}
            onChange={(event) => set_auto(event.target.checked)}
          />
          <HelpIcon
            text={
              "Save this response and respond with it again later " +
              "when we get the same directive request."
            }
          />
          <Button
            variant="primary"
            onClick={() => {
              dispatch(
                respond({
                  request_id,
                  directive,
                  status_code,
                  response: response_encoded,
                  execution_ms: Date.now() - received_at,
                  device_responded_at: Date.now(),
                })
              );
              if (auto) {
                dispatch(
                  saved_auto_response({
                    directive,
                    status_code,
                    response: response_encoded,
                  })
                );
              }
              close();
            }}
          >
            Send Response <MdSend />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const URLS = [
  "wss://mqtt.glowpush.com:8983",
  "ws://mqtt.glowpush.com:1983",
  "wss://localhost:8983",
  "ws://localhost:1983",
];

function ConnectForm({ hardware_identifier }) {
  let dispatch = useDispatch();
  let [broker_url, set_broker_url] = useState(URLS[0]);
  return (
    <div className="text-center">
      <h4>{hardware_identifier}</h4>
      <Dropdown onSelect={(url) => set_broker_url(url)}>
        <Dropdown.Toggle variant="secondary">
          {!broker_url ? <small>Custom</small> : <code>{broker_url}</code>}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {URLS.map((url) => (
            <Dropdown.Item key={url} eventKey={url} active={broker_url === url}>
              <code>{url}</code>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Button
        variant="primary"
        className="mt-4"
        onClick={() =>
          dispatch(
            connect({
              hardware_identifier,
              broker_url,
            })
          )
        }
      >
        Connect
      </Button>
    </div>
  );
}

function HelpIcon({ text }) {
  return (
    <OverlayTrigger
      transition={null}
      overlay={<Tooltip id="tooltip">{text}</Tooltip>}
    >
      {({ ref, ...triggerHandler }) => (
        <a ref={ref} {...triggerHandler}>
          <MdHelp />
        </a>
      )}
    </OverlayTrigger>
  );
}

function EventForm() {
  let [event, set_event] = useState(topics.Event.EVENT_UNSPECIFIED);
  let [filter, set_filter] = useState("");
  let [payload, set_payload] = useState({ is_ready: true, args: {} });
  let dispatch = useDispatch();

  let payload_name, payload_type;
  if (event) {
    payload_name = _.upperFirst(_.camelCase(event_names_by_value[event]));
    payload_type = proto.lookupType(payload_name);
  }
  return (
    <Card>
      <Card.Body>
        <Dropdown onSelect={(value) => set_event(value)}>
          <Dropdown.Toggle variant={event ? "primary" : "secondary"}>
            {!event ? (
              <small>Pick an event to emit</small>
            ) : (
              _.startCase(_.camelCase(event_names_by_value[event]))
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Header>
              <Form.Control
                autoFocus
                className="w-auto"
                placeholder=""
                onChange={(e) => set_filter(e.target.value)}
                value={filter}
              />
            </Dropdown.Header>
            {_.orderBy(
              Object.entries(topics.Event).filter(
                ([name, value]) =>
                  value &&
                  (!filter || name.toLowerCase().includes(filter.toLowerCase()))
              ), // exclude unspecified (value = 0)
              ([name, value]) => name
            ).map(([name, value]) => (
              <Dropdown.Item
                key={value}
                eventKey={value}
                active={event === value}
              >
                {_.startCase(_.camelCase(name))}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        {!payload_type ? null : (
          <ProtoTypeForm
            type={payload_type}
            args={payload.args}
            onChange={(payload) => set_payload(payload)}
          />
        )}
      </Card.Body>
      {!event ? null : (
        <Card.Footer>
          <Button
            onClick={() => set_event(topics.Event.EVENT_UNSPECIFIED)}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            className="float-right"
            disabled={!payload.is_ready}
            onClick={() =>
              dispatch(
                emit({
                  event_id: uuid(),
                  device_emitted_at: Date.now(),
                  event,
                  info: payload_type.encode(payload.args).finish(),
                })
              )
            }
            variant="primary"
          >
            Emit <MdSend />
          </Button>
        </Card.Footer>
      )}
    </Card>
  );
}

function ProtoTypeForm({ type, args, onChange }) {
  if (type.fieldsArray.length === 0) {
    return null;
  }
  return (
    <Form className="mt-3">
      {type.fieldsArray.map((field) => (
        <ProtoFormField
          field={field}
          key={field.name}
          value={args[field.name]}
          onChange={(v) =>
            onChange({
              is_ready: true,
              args: { ...args, [field.name]: v },
            })
          }
        />
      ))}
    </Form>
  );
}

function ProtoFormField({ field, value, onChange }) {
  if (field.type === "bool") {
    return (
      <Form.Check
        type="checkbox"
        className="mb-3"
        size="sm"
        id={field.name}
        label={_.startCase(_.camelCase(field.name))}
        checked={!!value}
        onChange={(event) => onChange(event.target.checked)}
      />
    );
  }
  if (
    field.resolvedType &&
    field.resolvedType.constructor.className === "Enum"
  ) {
    // give pithy names to enums by stripping the common prefix
    //  e.g. "OPERATING_MODE_STATION" -> "Station"
    let prefix = common_prefix(Object.keys(field.resolvedType.values));
    return (
      <Form.Group controlId={field.name} size="sm">
        <Form.Label size="sm">
          {_.startCase(_.camelCase(field.name))}
        </Form.Label>
        <Form.Control
          as="select"
          size="sm"
          value={value || 0}
          onChange={(event) => onChange(event.target.value)}
        >
          {Object.entries(field.resolvedType.values).map(
            ([value_name, value_number]) => (
              <option key={value_number} value={value_number}>
                {/* simplify names: "OPERATING_MODE_STATION" -> "Station" */}
                {_.startCase(_.camelCase(value_name.substring(prefix.length)))}
              </option>
            )
          )}
        </Form.Control>
      </Form.Group>
    );
  }
  if (
    field.resolvedType &&
    field.resolvedType.constructor.className === "Type"
  ) {
    return (
      <fieldset>
        <Form.Label>{_.startCase(_.camelCase(field.name))}</Form.Label>
        {field.resolvedType.fieldsArray.map((sub_field) => (
          <ProtoFormField
            key={sub_field.name}
            field={sub_field}
            value={value && value[sub_field.name]}
            onChange={(v) =>
              onChange({ ...(value || {}), [sub_field.name]: v })
            }
          />
        ))}
      </fieldset>
    );
  }
  return (
    <Form.Group controlId={field.name} size="sm">
      <Form.Label>{_.startCase(_.camelCase(field.name))}</Form.Label>
      <Form.Control
        onChange={(event) => onChange(event.target.value)}
        value={value || ""}
        type="text"
        placeholder={field.name}
      />
    </Form.Group>
  );
}

// Generic Helpers
// TODO: consolidate (maybe into common/src/js?)

function common_prefix(names) {
  let sorted = names.concat().sort();
  let first = sorted[0];
  let last = sorted[sorted.length - 1];
  let x = _.takeWhile(first, (c, i) => last.charAt(i) === c);
  return x.join("");
}
