/**
 * This provides an SVG implementation of the coaster.
 */

// convert byte N to consistent hex string (2-digit, zero-padded, uppercase)
const hex = (n) => ("00" + (n & 0xff).toString(16).toUpperCase()).slice(-2);
export default function SvgCoaster({ className, colors }) {
  return (
    <svg className={className} viewBox="0 0 1066 1076">
      <defs>
        {colors.map(({ r, g, b }, i) => {
          // make the alpha approx the vibrancy of the 3 colors
          // to more closely approximate the physical LED.
          //   e.g. black is "off" -> alpha becomes (0 + 0 + 0)/3 = 0
          let a = Math.round((r + g + b) / 3);
          let color = `#${hex(r)}${hex(g)}${hex(b)}${hex(a)}`;
          return (
            <radialGradient
              key={i}
              cx="50%"
              cy="0%"
              fx="50%"
              fy="0%"
              r="100%"
              gradientTransform="translate(0.5,0),scale(0.5,1),rotate(90),translate(-0.5,0)"
              id={`color_${i}`}
            >
              <stop stopColor={color} offset="0%"></stop>
              <stop stopColor={color} stopOpacity="0" offset="100%"></stop>
            </radialGradient>
          );
        })}
      </defs>
      <g id="disc_light" transform="translate(0.107188, 0.377638)">
        <g id="flares" transform="translate(0.000000, 0.000000)">
          <polygon
            fill="url(#color_0)"
            id="flare_0"
            points="532.892812 713.622362 792.392812 1013.62236 273.392812 1013.62236"
          ></polygon>
          <polygon
            fill="url(#color_1)"
            id="flare_1"
            transform="translate(341.274820, 801.361902) rotate(36.000000) translate(-341.274820, -801.361902) "
            points="341.27482 651.361902 600.77482 951.361902 81.7748195 951.361902"
          ></polygon>
          <polygon
            fill="url(#color_2)"
            id="flare_2"
            transform="translate(222.848387, 638.361902) rotate(72.000000) translate(-222.848387, -638.361902) "
            points="222.848387 488.361902 482.348387 788.361902 -36.6516125 788.361902"
          ></polygon>
          <polygon
            fill="url(#color_3)"
            id="flare_3"
            transform="translate(222.848387, 436.882822) rotate(108.000000) translate(-222.848387, -436.882822) "
            points="222.848387 286.882822 482.348387 586.882822 -36.6516125 586.882822"
          ></polygon>
          <polygon
            fill="url(#color_4)"
            id="flare_4"
            transform="translate(341.274820, 273.882822) rotate(144.000000) translate(-341.274820, -273.882822) "
            points="341.27482 123.882822 600.77482 423.882822 81.7748195 423.882822"
          ></polygon>
          <polygon
            fill="url(#color_5)"
            id="flare_5"
            transform="translate(532.892812, 211.622362) rotate(180.000000) translate(-532.892812, -211.622362) "
            points="532.892812 61.6223623 792.392812 361.622362 273.392812 361.622362"
          ></polygon>
          <polygon
            fill="url(#color_6)"
            id="flare_6"
            transform="translate(724.510804, 273.882822) rotate(216.000000) translate(-724.510804, -273.882822) "
            points="724.510804 123.882822 984.010804 423.882822 465.010804 423.882822"
          ></polygon>
          <polygon
            fill="url(#color_7)"
            id="flare_7"
            transform="translate(842.937236, 436.882822) rotate(252.000000) translate(-842.937236, -436.882822) "
            points="842.937236 286.882822 1102.43724 586.882822 583.437236 586.882822"
          ></polygon>
          <polygon
            fill="url(#color_8)"
            id="flare_8"
            transform="translate(842.937236, 638.361902) rotate(288.000000) translate(-842.937236, -638.361902) "
            points="842.937236 488.361902 1102.43724 788.361902 583.437236 788.361902"
          ></polygon>
          <polygon
            fill="url(#color_9)"
            id="flare_9"
            transform="translate(724.510804, 801.361902) rotate(324.000000) translate(-724.510804, -801.361902) "
            points="724.510804 651.361902 984.010804 951.361902 465.010804 951.361902"
          ></polygon>
        </g>
        <circle
          id="center"
          fill="#444444"
          cx="533.561207"
          cy="538.622362"
          r="202"
        ></circle>
      </g>
    </svg>
  );
}
