import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { useEffect, useState } from "react";

export const firebase_app = firebase.initializeApp({
  apiKey: "AIzaSyBz9RY7g3Iev1LrdAXzZWqw3Uq2WAQIMlI",
  authDomain: "glow-prod.firebaseapp.com",
  databaseURL: "https://glow-prod.firebaseio.com",
  projectId: "glow-prod",
  storageBucket: "glow-prod.appspot.com",
  messagingSenderId: "138862362207",
  appId: "1:138862362207:web:50e0514a603b6a8271a5ea",
});

/**
 * This hook returns whether the user is authenticated (signed in)
 * and whether they are authorized (allowed) to use the admin app.
 *
 * NOTE: these are `null` when we don't know yet
 *       i.e. when we're still waiting for firebase to resolve true/false
 * @returns {{
 *   is_signed_in: boolean|null,
 *   is_allowed: boolean|null,
 *   }}
 */
export function useAuth() {
  const [is_signed_in, set_is_signed_in] = useState(null); // null until true/false
  const [is_allowed, set_is_allowed] = useState(null); // null until true/false
  useEffect(() => {
    const stop = firebase_app.auth().onAuthStateChanged(async (user) => {
      set_is_signed_in(!!user);
      if (user) {
        try {
          let record = await firebase_app
            .database()
            .ref(`/users/${user.uid}`)
            .once("value");
          let is_simulator = record.exists() && record.val().is_simulator;
          set_is_allowed(!!is_simulator);
        } catch (err) {
          console.error("failure authorizing", user, err);
          set_is_allowed(false);
        }
      } else {
        set_is_allowed(null);
      }
    });
    return () => stop();
  }, []);
  return { is_signed_in, is_allowed };
}

export default firebase;
