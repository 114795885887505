import { useSelector } from "react-redux";
import _ from "lodash";
import GLCoaster from "./GLCoaster";
import SvgCoaster from "./SvgCoaster";

const DEFAULT_COLORS = [
  { r: 0xc4, g: 0xe5, b: 0xc9 },
  { r: 0xf8, g: 0xb1, b: 0x84 },
  { r: 0xc8, g: 0xc3, b: 0xf8 },
  { r: 0xef, g: 0xf5, b: 0x7c },
  { r: 0xd1, g: 0xe5, b: 0xf8 },
];

// Give a consistent color to correspond to the number N
function colorN(n) {
  return DEFAULT_COLORS[n % DEFAULT_COLORS.length];
}

// This is a "low-level" disc with 10 lights that just takes the colors
// for each LED.
//
// Think of updating the `colors` property here
// as if you're blitting to the device LEDs.
// See `drivers_blit_leds()` in `coaster_drivers.h`
//
// Each of `colors` should contain an array of
// the current r/g/b values (out of 255)
// for each of the 10 LEDs.
export default function Coaster({
  className,
  full = false,
  colors = _.times(10, (n) => ({ r: 0, g: 0, b: 0 })),
}) {
  let render_type = useSelector((state) => state.visual.render_type);
  let inspect = useSelector((state) => state.visual.inspect);
  if (colors.length < 10) {
    colors = []
      .concat(colors)
      .concat(_.times(10 - colors.length, (n) => ({ r: 0, g: 0, b: 0 })));
  }
  let Type = render_type === "svg" ? SvgCoaster : GLCoaster;
  return (
    <Type full={full} inspect={inspect} className={className} colors={colors} />
  );
}

const conv32to8bit = (n) => _.clamp(Math.round(n / Math.pow(2, 24)), 0, 255);
export function GroupCoaster({ className, full, onActivated }) {
  let group = useSelector((state) => state.client.group);

  if (!group) {
    // Before we have a group, show the loading-coaster spinning w/ all the colors.
    return (
      <Coaster
        full={full}
        className={className}
        colors={_.times(10, (n) => (n % 2 ? { r: 0, g: 0, b: 0 } : colorN(n)))}
      />
    );
  }
  let { group_members } = group.group_state;
  let colors = group_members.map(({ color: { red, green, blue } }) => ({
    r: conv32to8bit(red),
    g: conv32to8bit(green),
    b: conv32to8bit(blue),
  }));
  return <Coaster full={full} className={className} colors={colors} />;
}
