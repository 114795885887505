import { createSlice } from "@reduxjs/toolkit";

export const visualSlice = createSlice({
  name: "visual",
  initialState: {
    full_screen: false,
    render_type: "svg",
    table_type: "wood",
    inspect: false,
  },
  reducers: {
    set_full_screen: (state, action) => {
      state.full_screen = action.payload;
    },
    set_render_type: (state, action) => {
      state.render_type = action.payload;
    },
    set_table_type: (state, action) => {
      state.table_type = action.payload;
    },
    set_inspect: (state, action) => {
      state.inspect = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  set_full_screen,
  set_inspect,
  set_render_type,
  set_table_type,
} = visualSlice.actions;
export default visualSlice.reducer;
